<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
  integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous">
<!-- <link rel="stylesheet" href="../../../assets/css/login.css"> -->

<block-ui>
  <section class="d-flex align-items-center justify-content-center" style="height: 100%;">
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-md-9 col-lg-6 col-xl-5">
          <img src="assets/img/pruebalogo.png" class="img-fluid" alt="Sample image">
          <!--  <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              class="img-fluid" alt="Sample image"> -->
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">

          <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
            <p class="lead fw-normal mb-0 me-3">Redes Sociales </p>
            <a href="https://www.facebook.com/comeppsidgo/" type="button" class="btn btn-primary btn-floating mx-1"
              target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>

            <a type="button" href="https://twitter.com/CoMePPsi/status/1523696771842535425"
              class="btn btn-primary btn-floating mx-1" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>

            <a type="button" href="https://www.instagram.com/comeppsi_dgo/" class="btn btn-primary btn-floating mx-1"
              target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
          </div>

          <div class="divider d-flex align-items-center my-4">
            <!--    <p class="text-center fw-bold mx-3 mb-0">Or</p> -->
          </div>

          <!-- Email input -->
          <div class="form-outline mb-4">
            <label class="form-label" for="mail"><b>Email</b> </label>
            <input type="email" id="mail" class="form-control form-control-lg" placeholder="Ingrese un correo valido"
              [(ngModel)]="log.email" />

          </div>

          <!-- Password input -->
          <div class="form-outline mb-3">
            <label class="form-label" for="pass"><b>Password</b></label>
            <input type="password" id="pass" class="form-control form-control-lg" placeholder="Ingrese password"
              [(ngModel)]="log.password" />

          </div>

          <div class="d-flex justify-content-between align-items-center">
            <!-- Checkbox -->
            <div class="form-check mb-0">
              <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
              <label class="form-check-label" for="form2Example3">
                Recuerdame
              </label>
            </div>
            <a href="#!" data-toggle="modal" data-target="#exampleModal" data-toggle="modal" data-target="#exampleModal"
              class="text-body">Olvidaste tu password?</a>
          </div>

          <div class="text-center text-lg-start mt-4 pt-2">
            <button type="button" class="btn btn-primary btn-lg" id="btnLogin"
              style="padding-left: 2.5rem; padding-right: 2.5rem;" (click)="login(log);">
              <i class="fa fa-spinner fa-spin" *ngIf="spinLoader==true"> </i>
              Login</button>
            <p class="small fw-bold mt-2 pt-1 mb-0">Aún no tienes tu cuenta? <a href="#!" class="link-danger"
                routerLink="/registrar">Registrate</a></p>
          </div>

        </div>
      </div>
    </div>

    <!--Loader-->
 
    <!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" id="open" data-target="#exampleModalCenter" style="display: none;">
  Launch demo modal
</button> -->

<!-- Modal -->
<!-- <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" >

    
      <div class="spinner" style="text-align:center;"><img src="assets/img/cargando.gif"></div>
      <button type="button" id="close" #myModalClose class="close" data-dismiss="modal" style="display: none;">&times;</button>
    </div>
  </div>
</div> -->
      <!---->
  </section>
  <div
    class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
    <div _ngcontent-fhg-c1="" class="text-white mb-3 mb-md-0"> Copyright © CoMMePsi 2022. Todos los derechos reservados.
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Reuperación de password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="text" class="form-control" placeholder="Ingrese su correo electronico" [(ngModel)]="correo">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeButton">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="repuperaPass(correo)">Recuperar Password</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-atom" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
 -->
</block-ui>