<h3>Pago de Anualidad</h3><br><br>
	<div id="pago" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Pago Anualidad</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar()" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="valida()" [disabled]="btn1">Subir archivo</button>
        </span>
      </div>
      <div id="pago_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress}}%</b></span> 
        </div>
      </div><br><br>
