import {  Component, OnInit } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { AfiliadosService } from '../../services/afiliados.service';
import { DocumentosService} from '../../services/documentos.service';
import { _TOKEN } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import 'jqueryui';
/* import * as jQuery from "jquery-ui"; */

@Component({
  selector: 'app-afiliados',
  templateUrl: './afiliados.component.html',
  styleUrls: ['./afiliados.component.css']
})
export class AfiliadosComponent implements OnInit {
  idx!:number;
  personas:any;
  years:any;
  arrayanios: string[] = []; 
  datosp:any;
  aniogbl!:number;
  docs:any;
  constructor(private http: HttpClient,private _afiliado: AfiliadosService,private _doc: DocumentosService,public router: Router) { }

  ngOnInit() {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
  }
    this.idx=sessionStorage.Id;
    this.getAnios();
    $('#UpdateDatos').draggable(); 
    $('#exampleModalCenter').draggable(); 

  }
  getAfiliados(anio:number){
   
    this.aniogbl=anio;
    this._afiliado.getAfiliados(anio).subscribe(
      afiliados => {
        this.personas = afiliados;
        console.log(this.personas);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  getDatosPers(id:number){
    this._afiliado.getDatosPersonales(id).subscribe(
      datop => {
        this.datosp = datop;
       
        for(var i=0;i<this.datosp.length;i++){
          this.datosp[0].f_nacimiento=this.datosp[0].f_nacimiento.substring(0,10);
          this.datosp[0].f_ingreso=this.datosp[0].f_ingreso.substring(0,10);
          
        }
        console.log(this.datosp);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }

  getAnios(){
    this._afiliado.getAnios().subscribe(
      anios => {
        this.years = anios;
        console.log(this.years);
        this.arrayanios.push(this.years[0].anio1);
        this.arrayanios.push(this.years[0].anio2);
        this.arrayanios.push(this.years[0].anio3);
        console.log(this.arrayanios);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }


  UpdateDatosPer(act: any): void {
    this._afiliado.updateDatosPer(act).subscribe(dp => {
      this.router.navigate(['/afiliados']);
        Swal.fire('Afiliado ', `Afiliado Actualizado con éxito!`, 'success');
        this.getAfiliados(this.aniogbl);
        var closebutton = document.querySelector('#closeUpdateDP') as HTMLVideoElement;
        closebutton.click();
       
    });
  }



  enviaCorreo(id:number){
    this._afiliado.getEnvioCorreo(id).subscribe(notifica => {
      if(notifica){
        Swal.fire('Correo de Felicitación ', `Correo de Felicitación  Enviado con éxito!`, 'success');
      }
    });
  }



  getDocumentos(id:number){
    this._doc.getDocumentos(id).subscribe(
      documentos => {
        this.docs = documentos;
        console.log(this.docs);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  recibo(ruta:string){
    //window.open("../../../../assets/pruebarec/Doc.pdf");
    //window.open("https://psicoexpediente.com/"+ruta);
      window.open("../../../../"+ruta);
  }

}
