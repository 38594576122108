import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Anualidad } from '../interfaces/anualidad';
import { Presidente } from '../interfaces/presi';
import { HttpClient } from '@angular/common/http';
import { environment, _TOKEN } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GraficaavanceService {
  private urlEndPoint = `${environment.rutaAPI}/UpdatePresi`;

  constructor(private http: HttpClient, public router: Router) { }

  getPresidente() {
    return this.http.get(`${environment.rutaAPI + '/GetPuesto'}`);
  }

  getAnualidad() {
    return this.http.get(`${environment.rutaAPI + '/GetAnualidad'}`);
  }

  getRecaudacion() {
    return this.http.get(`${environment.rutaAPI + '/Recaudacion'}`);
  }

  getRecaudacionAnio(anio:number) {
    return this.http.get(`${environment.rutaAPI + '/RecaudacionAnio/'+anio}`);
  }

  getGraficaNow() {
    return this.http.get(`${environment.rutaAPI + '/GetDatosGraficaNow'}`);
  }

  getGraficaAnio(anio:number) {
    return this.http.get(`${environment.rutaAPI + '/GetDatosGrafica/'+anio}`);
  }

  updateAnualidad(an: Anualidad): Observable<Anualidad> {
    an.catp_id=1;
    return this.http.post<Anualidad>(`${environment.rutaAPI + '/UpdateAnualidad'}/${an.catp_id}`, an);
  }

  updatePresidente(nombre: string) {	
    let x=1;
    return this.http.post(`${this.urlEndPoint + '?id='+x+'&nombre='+nombre}`, "");
	}

}
