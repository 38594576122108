<h3>Documentos subidos</h3>
    <form>
        <div class="tableFixHead">
            <table class="table table-striped" style="font-size:12px;">
                <thead>
                    <tr>
                        <th scope="col" ><span>Documento</span></th>
                        <th scope="col" ><span>Ver Documento</span></th>
                        <th scope="col" ><span>Eliminar Documento</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let documents of this.docs;index as i">
                        <td>{{documents.tipodoc_desc }}</td>
                        <td><input type="hidden" ><button type="button" class="btn btn-primary btn-sm" (click)="recibo(documents.doc_ruta)"><i class="fa-solid fa-file-image"></i></button></td>
                        <td><input type="hidden" ><button type="button" class="btn btn-danger btn-sm" (click)="EliminaDocumentos(documents.doc_ruta,documents.doc_id)"><i class="fa-regular fa-trash-can"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
   
<!--         <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="total$ | async" [(page)]="countryService.page" [pageSize]="countryService.pageSize"></ngb-pagination
            ><select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="countryService.pageSize">
                <option [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
            </select>
        </div> -->
    </form>
