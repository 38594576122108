<h3>Documentación</h3><br><br>
 <div id="contenedor">
      <div id="tit" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Título profesional</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(1)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(1)"  [disabled]="btn1">Subir archivo</button>
        </span><br>
      </div>

      <div id="tit_p"  class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress}}%</b></span> 
        </div>
      </div><br><br>

      <div id="ced" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Cédula profesional</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(2)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(2)" [disabled]="btn2">Subir archivo</button>
        </span>
      </div>

      <div id="ced_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress2}}%</b></span> 
        </div>
      </div><br><br>

      <div id="cur" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Curriculum Profesional</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(3)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(3)" [disabled]="btn3">Subir archivo</button>
        </span>
      </div>
      <div  id="cur_p" class=" input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress3}}%</b></span> 
        </div>
      </div><br><br>

      <div id="formu" class=" input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Formulario de afiliación CoMePPsi</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(4)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(4)" [disabled]="btn4">Subir archivo</button>
        </span>
      </div>

      <div id="formu_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress4}}%</b></span> 
        </div>
      </div><br><br>

      <div id="ficha" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Ficha de Identificación</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(5)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(5)" [disabled]="btn5">Subir archivo</button>
        </span>
      </div>
      <div id="ficha_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress5}}%</b></span> 
        </div>
      </div><br><br>

      <div id="carta" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Carta Exposición Motivos</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(6)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(6)" [disabled]="btn6">Subir archivo</button>
        </span>
      </div>
      <div id="carta_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress6}}%</b></span> 
        </div>
      </div><br><br>

      <div id="curp" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>CURP</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(8)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(8)" [disabled]="btn8">Subir archivo</button>
        </span>
      </div>

      <div id="curp_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress8}}%</b></span> 
        </div>
      </div><br><br>

      <div id="ife" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>IFE</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(9)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(9)" [disabled]="btn9">Subir archivo</button>
        </span>
      </div>

      <div id="ife_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress9}}%</b></span> 
        </div>
      </div><br><br>

      <div id="act" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Acta de Nacimiento</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(10)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(10)" [disabled]="btn10">Subir archivo</button>
        </span>
      </div>

      <div id="act_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress10}}%</b></span> 
        </div>
      </div><br><br>

      <div id="img" class="input-group input-group-sm">
        <div class="col-3">
          <label for="titulo" class="form-label"><b>Imagen para Directorio</b></label> 
        </div>
        <input type="file" class="form-control"(change)="onFileSelected($event);Habilitar(11)" #fileInput>&nbsp; &nbsp;
        <span class="input-group-btn" >
            <button class="btn btn-primary btn-sm" type="button" role="button" (click)="onUpload(11)" [disabled]="btn11">Subir archivo</button>
        </span>
      </div>

      <div id="img_p" class="input-group input-group-sm">
        <div class="col-5">
          <span id="file-label"><b>Porcentaje de subida: {{this.progress11}}%</b></span> 
        </div>
      </div>
    </div><!--Termina el contenedor-->
  



