import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UpdpasswordService } from '../../services/updpassword.service';
import { _TOKEN } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sb-updpassword',
  templateUrl: './updpassword.component.html',
  styleUrls: ['./updpassword.component.scss']
})
export class UpdpasswordComponent implements OnInit {
  pass: string = '';
  idx!:number;
  private subscription: Subscription = new Subscription;
  constructor(private router: Router,  private _upass: UpdpasswordService) { }

  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
  }

  this.idx=sessionStorage.Id;
  }
/*   Actualizar(){
    alert(this.pass);
    //alert(this.pasword);
  } */

  Actualizar() {
    this.subscription = this._upass.create(this.idx,this.pass)
        .subscribe((data: any) => {
            if ( data != null) {
                swal.fire({
                    icon: 'success',
                    title: 'Password Cambiado',
                    text: data,
                    timer: 2000
                });
                this.router.navigate(['/dashboard']);
            } 
        },
      
         error => {
            
            swal.fire({
                title: 'ERROR!!!',
                text: error.error.Message,
                icon: 'error'});
                console.log(error.Message);
        }
    
        );
    }

}
