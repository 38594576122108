<!-- <sb-layout-dashboard>
    <sb-dashboard-head title="Afiliados" [hideBreadcrumbs]="false"></sb-dashboard-head> -->

        <h3>Afiliados</h3>
        <div class="form-group">
            <label for="anio">Año</label>
            <select class="form-control" (change)="getAfiliados($event.target.value)">
                <option selected="selected" value="0">-- Seleccione una año --</option>
                <option *ngFor="let cp of arrayanios" value="{{ cp }}">{{cp}}</option>
               </select>
        </div>
        <div class="tableFixHead">
            <table class="table table-striped" style="font-size:12px;">
                <thead>
                    <tr>
                        <th scope="col"><span>Integrante</span></th>
                        <th scope="col"><span>Estatus</span></th>
                        <th scope="col"><span>Cumpleaños</span></th>
                        <th scope="col"><span>Caducidad</span></th>
                        <th scope="col"><span>Documentación</span></th>
                        <th scope="col"><span>Editar</span></th>
                        <th scope="col"><span>Enviar Felicitación</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let af of this.personas;index as i">
                        <td>{{af.nombre_completo}}</td>
                        <td>{{af.est_desc}}</td>
                        <td>{{af.cumple}}</td>
                        <td>{{af.pago_fecha_cad}}</td>
                        <td><button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModalCenter" (click)="getDocumentos(af.id)">Ver Documentos</button></td>
                        <td><button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#UpdateDatos" (click)="getDatosPers(af.id)">Editar</button></td>
                        <td><button class="btn btn-success btn-sm" (click)="enviaCorreo(af.id)">Enviar correo</button></td>
                    </tr>
                </tbody>
            </table>
        </div>

            <!--Modal update datos personales  data-backdrop="false" style="background-color: rgba(0, 0, 0, 0.5);"-->
    <div class="modal fade bd-example-modal-lg" id="UpdateDatos" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="false">
        <br><br>
         <div class="modal-dialog modal-lg " >
           <div class="modal-content" *ngFor="let dp of this.datosp;index as i">
             <div class="modal-header">
               <h5 class="modal-title" id="exampleModalLongTitle">Datos Personales</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
               </button>
             </div>
             <div class="modal-body" >
               <div class="form-row">
                 <div class="form-group col-md-3">
                   <label for="paterno">Paterno</label>
                   <input type="text" class="form-control input-sm" [(ngModel)]="dp.paterno" id="paterno"  >
                 </div>
                 <div class="form-group col-md-3">
                   <label for="materno">Materno</label>
                   <input type="text" class="form-control input-sm" [(ngModel)]="dp.materno" id="materno" >
                 </div>
                 <div class="form-group col-md-3">
                   <label for="nombre">Nombre</label>
                   <input type="text" class="form-control input-sm" [(ngModel)]="dp.nombre" id="nombre" >
                 </div>
                 <div class="form-group col-md-3">
                   <label for="fecha">Fecha de Nacimiento</label>
                   <input type="date" class="form-control input-sm" [(ngModel)]="dp.f_nacimiento" id="fecha" >
                 </div>
                 <div class="form-group col-md-3">
                    <label for="fecha">Fecha de Ingreso</label>
                    <input type="date" class="form-control input-sm" [(ngModel)]="dp.f_ingreso" id="fechaing" >
                  </div>
                  
                 <div class="form-group col-md-3">
                   <label for="email">E-Mail</label>
                   <input type="email" class="form-control input-sm" [(ngModel)]="dp.email" id="email" >
                 </div>
                 <div class="form-group col-md-3">
                   <label for="telefono">Teléfono</label>
                   <input type="text" class="form-control input-sm" [(ngModel)]="dp.telefono" id="telefono" >
                 </div>
                 <div class="form-group col-md-3">
                   <label for="cel">Celular</label>
                   <input type="text" class="form-control input-sm" [(ngModel)]="dp.celular" id="cel" >
                 </div>
                 <div class="form-group col-md-3">
                   <label for="cp">Codigo Postal</label>
                   <input type="number" class="form-control input-sm" [(ngModel)]="dp.cp"  id="cp" >
                 </div>
                 <div class="form-group col-md-12">
                   <label for="genero">Género </label>
                   <select class="form-control sm" name="genero"  id="genero"  [(ngModel)]="dp.genero">
                     <option [ngValue]=1>MASCULINO</option>
                     <option [ngValue]=2>FEMENINO</option>
                     <option [ngValue]=3>NO DESEO MANIFESTAR</option>
                 </select>
                 </div>

                 <div class="form-group col-md-12">
                    <label for="genero">Tipo de Usuario </label>
                    <select class="form-control sm" name="genero"  id="genero"  [(ngModel)]="dp.tipo_usuario">
                      <option [ngValue]=1>ADMINISTRADOR</option>
                      <option [ngValue]=2>USUARIO</option>
                  
                  </select>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="genero">Estatus </label>
                    <select class="form-control sm" name="genero"  id="genero"  [(ngModel)]="dp.estatus">
                      <option [ngValue]=1>ACTIVO</option>
                      <option [ngValue]=2>INACTIVO</option>
                      <option [ngValue]=3>PENDIENTE</option>
                  </select>
                  </div>
                 <div class="form-group col-md-9">
                   <label for="direccion">Dirección</label>
                   <input type="text" class="form-control input-sm" [(ngModel)]="dp.direccion" id="direccion" >
                 </div>
                 <div class="form-group col-md-3">
                   <label for="noext">No Ext</label>
                   <input type="number" class="form-control input-sm" [(ngModel)]="dp.no_ext"  id="noext" >
                 </div>
                 <div class="form-group col-md-12">
                   <label for="calle1">Calle 1</label>
                   <input type="text" class="form-control input-sm" [(ngModel)]="dp.calle1"  id="calle1" >
                 </div>
                 <div class="form-group col-md-12">
                   <label for="calle2">Calle 2</label>
                   <input type="text" class="form-control input-sm" [(ngModel)]="dp.calle2"  id="calle2" >
                 </div>
               </div>
             </div>
             <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeUpdateDP" >Cerrar</button>
               <button type="button" class="btn btn-primary" (click)="UpdateDatosPer(dp);">Guardar</button>
             </div>
           </div>
         </div>
       </div>



      <!-- Modal -->
      <div class="modal fade bd-example-modal-lg" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Documentos</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="tableFixHead">
                <table class="table table-striped" style="font-size:12px;">
                    <thead>
                        <tr>
                            <th scope="col" ><span>Documento</span></th>
                            <th scope="col" ><span>Ver Documento</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let documents of this.docs;index as i">
                            <td>{{documents.tipodoc_desc }}</td>
                            <td><input type="hidden" ><button type="button" class="btn btn-primary btn-sm" (click)="recibo(documents.doc_ruta)"><i class="fa-solid fa-file-image"></i></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <!--       <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
          </div>
        </div>
      </div>
        

<!-- </sb-layout-dashboard> -->