import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-spinner',
  template:`
  <div class="overlay" *ngIf="isLoading$ | async">
      <div class="lds-hourglass"></div>
  </div>
  `,
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
isLoading$=this.SpinnerScv.isLoading$;
  constructor(private SpinnerScv:SpinnerService) { }

  ngOnInit(): void {
  }

}
