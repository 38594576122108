import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { _TOKEN } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DirectorioService} from '../../services/directorio.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'sb-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.css']
})
export class DirectorioComponent implements OnInit {
directorio:any;
  constructor(private http: HttpClient,private _dir: DirectorioService,public router: Router) { }

  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
     }
     this.getDirectorio();
  }

  
  getDirectorio(){
    this._dir.getDirectorio().subscribe(
      dir => {
        this.directorio = dir;
        console.log(this.directorio);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

}
