import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { LoginService } from './login.service';
import { RegistrarService } from './registrar.service';
import { ValidadoresService } from './validadores.service';


import { LoginGuard } from './guards/login.guard';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    LoginGuard,
    LoginService,
    RegistrarService,
    ValidadoresService,
  
  ]
})
export class ServicesModule { }
