
    <h3>Grafica de Avance</h3>
    <script src="https://cdn.jsdelivr.net/npm/chartjs-plugin-datalabels@0.7.0"></script>
    <div class="row">
        <div class="col-md-6 mb-4 d-flex align-items-center">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" ><span>Presidente</span></th>
                        <th scope="col" ><span>Editar</span></th>
                    </tr>
                </thead>
                <tbody>
                <!--     *ngFor="let documents of this.docs;index as i" -->
                    <tr *ngFor="let presi of this.presidente;index as i">
                        <td><b>{{presi.puesto_nombre}}</b></td>
                        <td><input type="hidden" ><button type="button" class="btn btn-primary sm" data-toggle="modal" data-target="#exampleModalCenter" ><i class="fa-solid fa-user-pen"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6 mb-4 d-flex align-items-center">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" ><span>Anualidad</span></th>
                        <th scope="col" ><span>Descripción</span></th>
                        <th scope="col" ><span>Editar</span></th>
                    </tr>
                </thead>
                <tbody>
                <!--     *ngFor="let documents of this.docs;index as i" -->
                    <tr *ngFor="let an of this.anualidad;index as i">
                        <td><b>{{an.catp_cant}}</b></td>
                        <td><b>{{an.catp_desc}}</b></td>
                        <td><input type="hidden" ><button type="button" class="btn btn-primary sm" data-toggle="modal" data-target="#exampleModalCenter2" ><i class="fa-solid fa-user-pen"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="form-group">
        <label for="anio">Consultar otro año</label>
        <select class="form-control" (change)="getDatosGraficaYear($event.target.value);getRecaudacionAnio($event.target.value);">
            <option selected="selected" value="0">-- Seleccione un año --</option>
            <option *ngFor="let anio of arrayanios" value="{{ anio }}">{{anio}}</option>
           </select>
    </div>

        <div class="row">
            <div class="col-xl-6">
                <canvas id="myChart"></canvas> 
            </div>

            <div class="col-xl-6">
                <div class="form-group">
                    <label for="recauda">Recaudado en el año</label>
                    <input type="text" class="form-control"  [(ngModel)]="valrec" readonly>
                  </div>
      <!--         <sb-card>
                
                  <div class="card-body">
               
                
                  </div
              ></sb-card> -->
          </div>
        </div>



  
  <!-- Modal presidente -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" *ngFor="let presi of this.presidente;index as i">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Editar Presidente</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
         <input type="text" class="form-control"  [(ngModel)]="presi.puesto_nombre">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closePres">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="UpdatePresi(presi.puesto_nombre)">Guardar Cambios</button>
        </div>
      </div>
    </div>
  </div>

   <!-- Modal anualidad -->
   <div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" *ngFor="let an of this.anualidad;index as i">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Editar Anualidad</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label for="anualidad" class="col-form-label"><b>Anualidad:</b></label>
                <input type="number" class="form-control" id="anualidad" [(ngModel)]="an.catp_cant">
            </div>
            <div class="form-group">
                <label for="desc" class="col-form-label"><b>Descripción:</b></label>
                <input type="text" class="form-control" id="desc" [(ngModel)]="an.catp_desc">
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeAn">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="UpdateAn(an);">Guardar Cambios</button>
        </div>
      </div>
    </div>
  </div>

