import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, _TOKEN } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConstanciasService {

  constructor(private http: HttpClient) { }

  getConstancias(ids:number) {
		
    return this.http.get(`${environment.rutaAPI + '/GetConstancias/' + ids}`);
  }
}
