import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Documentos } from '../interfaces/documentos';
import { HttpClient } from '@angular/common/http';
import { environment,_TOKEN } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UpdpasswordService {
  private urlEndPoint = `${environment.rutaAPI}/cambiarpass`;
  constructor(private http: HttpClient, public router: Router) { }

  create(Usuario: number,password: string) {	
    //return this.http.post<Documentos>(`${environment.rutaAPI}` + '/RegDocs' + `/${Usuario}`, documento);
    return this.http.post(`${this.urlEndPoint + '?usuario='+Usuario+'&password='+password}`, "");
	}
}
