import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { _TOKEN } from '../../../environments/environment';
import { DocumentosService} from '../../services/documentos.service';
import Swal from 'sweetalert2';
import {Documentos} from '../../interfaces/documentos';

@Component({
  selector: 'sb-docsubidos',
  templateUrl: './docsubidos.component.html',
  styleUrls: ['./docsubidos.component.css']
})
export class DocsubidosComponent implements OnInit {

  constructor(private http: HttpClient,private _doc: DocumentosService,public router: Router) { }
  idx!:number;
  docs:any;
  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
  }

  this.idx=sessionStorage.Id;
  this.getDocumentos();
  }

  getDocumentos(){
    this._doc.getDocumentos(this.idx).subscribe(
      documentos => {
        this.docs = documentos;
        console.log(this.docs);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }



  recibo(ruta:string){
    //window.open("../../../../assets/pruebarec/Doc.pdf");
    window.open("../../../../"+ruta);
  }

  eliminar(ruta:string,id:number){
    console.log(ruta);
    alert('Archivo eliminado')
  }

 EliminaDocumentos(ruta:string,id:number){
    this._doc.Delete(ruta,id).subscribe(
      detDoc => {
        Swal.fire('Eliminado', `Archivo eliminado con éxito!`, 'success');
         console.log(detDoc);
         this.getDocumentos();
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }
}
