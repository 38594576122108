import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { _TOKEN } from '../../../environments/environment';
import { PagosService} from '../../services/pagos.service';
import Swal from 'sweetalert2';
import {Documentos} from '../../interfaces/documentos';

@Component({
  selector: 'sb-pagoanual',
  templateUrl: './pagoanual.component.html',
  styleUrls: ['./pagoanual.component.css']
})
export class PagoanualComponent implements OnInit {
  percentDone!: number;
  uploadSuccess!: boolean;
  idx!:number;
  btn1: boolean= true;
  selectedFile!: File;
  public urlEndPoint: string = `${environment.rutaAPI}`;
  progress : any;
  constructor(private http: HttpClient,private _doc: PagosService,public router: Router) { }


  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
        this.router.navigate(['/login']);
    }

    this.idx=sessionStorage.Id;
    }

  Habilitar(){
    this.btn1 = false;
 }

 onFileSelected(event:any){
  this.selectedFile = <File>event.target.files[0];
  //this.ToggleButton = false;
}

valida(){
  this._doc.getValidaPago(this.idx).subscribe(pago=>{
    console.log(pago);
    if(pago>0){
      Swal.fire('Guardado', `Su pago ya ha sido registrado anteriormente!`, 'info');
    }
    else{
      this.onUpload();
    }
  })
}

onUpload(){
  const fd = new FormData();
  var id:any;
  fd.append('image', this.selectedFile, this.selectedFile.name);
  this._doc.create(this.idx,this.selectedFile.name).subscribe(archivo =>{
      console.log(archivo);
       id=archivo;
      this.http.post(`${this.urlEndPoint+"/UploadPago?ruta="+this.selectedFile.name+"&id="+archivo}`,fd,{
        reportProgress: true,
        observe: 'events'
      }).subscribe(event=>{
        if (event.type === HttpEventType.UploadProgress){
          console.log("Progreso: " + Math.round (event.loaded / event.total! * 100)  + "%");
          this.progress = Math.round (event.loaded * 100 / event.total! );
        }
        else if(event.type === HttpEventType.Response){
          console.log(event);
          if (event.status == 201){
            Swal.fire('Guardado', `Pago Registrado con éxito!`, 'success')
            .then(() =>{
              this.Constancia(this.idx);
            });
          }
          
        }

      },error=>{
        //console.log(error);
        Swal.fire({
          title: 'ERROR!!!',
          text: error.message,
          icon: 'error'});
      })

  },
  error => {
    //console.log(error);
    Swal.fire({
      title: 'ERROR!!!',
      text: error.message,
      icon: 'error'});
  });

}

Constancia(id:number){
  window.open("https://api.comeppsi.com.mx/api/Constancia/"+id);
  //window.open("../../../../"+ruta);
}


}
