<h3>Directorio</h3><br><br>
    <form>
        <div class="tableFixHead">
            <table  class="table table-striped" style="font-size:12px;">
              <thead>
                <tr>
                    <th scope="col" ><span>Integrante</span></th>
                    <th scope="col" ><span>Colonia o Fraccionamiento</span></th>
                    <th scope="col" ><span>Teléfono</span></th>
                    <th scope="col" ><span>Celular</span></th>
                    <th scope="col" ><span>CP</span></th>
                </tr>
              </thead>
              <tbody>
                <tr  *ngFor="let dir of this.directorio;index as i">
                    <td>{{dir.nombrecompleto}}</td>
                    <td>{{dir.dire_completa}}</td>
                    <td>{{dir.telefono}}</td>
                    <td>{{dir.celular}}</td>
                    <td>{{dir.cp}}</td>
                </tr>
              </tbody>
            </table>
          </div>
    </form>

