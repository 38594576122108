<h3>Constancias</h3>
    <form>
        <div class="tableFixHead">
            <table class="table table-striped" style="font-size:12px;">
                <thead>
                    <tr>
                        <th scope="col" ><span>Nombre</span></th>
                        <th scope="col" ><span>Fecha de Pago</span></th>
                        <th scope="col" ><span>Fecha de Caducidad</span></th>
                        <th scope="col" ><span>Año</span></th>
                        <th scope="col" ><span>Constancia</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let con of this.constancias;index as i">
                        <td>{{con.nombre}}</td>
                        <td>{{con.pago_fecha}}</td>
                        <td>{{con.pago_fecha_cad}}</td>
                        <td>{{con.pago_anio}}</td>
                        <td><button type="button" class="btn btn-primary btn-sm" (click)="constancia(con.id,con.pago_anio)"><i class="fa-solid fa-file-image"></i></button></td>
                    </tr>
       
                </tbody>
            </table>
        </div>
    </form>


