export class Usuarios {

  id!: number;
  paterno!: string;
  materno!: string;
  nombre!: string;
  f_nacimiento!: Date;
  email!: string;
  telefono!: number;
  celular!: number;
  direccion!: string;
  no_ext!: string;
  calle1!: string;
  calle2!: string;
  cp!: string;
  genero!: string;
  password!: string;
  password2!: string;
  tipo_usuario!: string;
  f_ingreso!: Date;

}