import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Documentos } from '../interfaces/documentos';
import { HttpClient } from '@angular/common/http';
import { environment,_TOKEN } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PagosService {
  private urlEndPoint = `${environment.rutaAPI}/RegPagos`;
  constructor(private http: HttpClient, public router: Router) { }

  getValidaPago(ids:number) {
    return this.http.get(`${environment.rutaAPI + '/ValidaPago/' + ids}`);
  }

  create(Usuario: number,nombreArchivo: string) {	
    //return this.http.post<Documentos>(`${environment.rutaAPI}` + '/RegDocs' + `/${Usuario}`, documento);
    return this.http.post(`${this.urlEndPoint + '?usuario='+Usuario+'&nombreArchivo='+nombreArchivo}`, "");
	}


  uploadAndProgressSingleImage(file: File,id:any) {
   return this.http
      .post(`${environment.rutaAPI}/UploadPago/`+id, file, {
        reportProgress: true,
        observe: 'events',
      })
  }
}
