import {  Component, OnInit } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { VerificapagosService } from '../../services/verificapagos.service';
import { _TOKEN } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sb-verificapago',
  templateUrl: './verificapago.component.html',
  styleUrls: ['./verificapago.component.css']
})
export class VerificapagoComponent implements OnInit {
  idx!:number;
  personas:any;
  years:any;
  arrayanios: string[] = []; 
  ruta:any;
  constructor(private http: HttpClient,private _verifica: VerificapagosService,public router: Router) { }

  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
  }
   
    this.idx=sessionStorage.Id;
    this.getAnios();
  }

  getAfiliados(anio:number){
   
    this._verifica.getAfiliados(anio).subscribe(
      afiliados => {
        this.personas = afiliados;
        console.log(this.personas);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  getAnios(){
    this._verifica.getAnios().subscribe(
      anios => {
        this.years = anios;
        console.log(this.years);
        this.arrayanios.push(this.years[0].anio1);
        this.arrayanios.push(this.years[0].anio2);
        this.arrayanios.push(this.years[0].anio3);
        console.log(this.arrayanios);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  enviaCorreo(id:number){
    this._verifica.getEnvioCorreo(id).subscribe(notifica => {
      if(notifica){
        Swal.fire('Correo de Notificación ', `Correo de Notificación Enviado con éxito!`, 'success');
      }
    });
  }

  verPago(id:number){
   
    this._verifica.getRutaPago(id).subscribe(
      rutapago => {
        this.ruta = rutapago;
        console.log(this.ruta);
        window.open("../../../../"+this.ruta);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }

}
