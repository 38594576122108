<app-header (toggleSideBarForMe)="sideBarToggler()"></app-header>

<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>

<app-footer></app-footer>