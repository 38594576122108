import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ValidadoresService } from '../../services/validadores.service';
import { RegistrarService } from 'src/app/services/services.index';
import {Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
/* import { sha256, sha224 } from 'js-sha256'; */
import { Usuarios } from '../../interfaces/usuarios';



@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.css'],
})
export class RegistrarComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  forma: FormGroup;
  public usuario: Usuarios = new Usuarios();
  private subscription: Subscription;

  isHidden = true;
  isHiddenMatricula = false;



  constructor( private _serv: RegistrarService,
               public router: Router) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.blockUI.start();

    this.blockUI.stop();
  }

  create(user: Usuarios){
    //console.log(datos.form.value);
    if(user.password==user.password2){
        this._serv.create(user).subscribe(usr =>{
            this.router.navigate(['/login']);
          Swal.fire('Nuevo usuario', `Usuario ${usr.email} creado con éxito!`, 'success');
        },
        error => {
         
          Swal.fire({
            title: 'ERROR!!!',
            text: error.error.Message,
            icon: 'error'});
        });
    }
    else{
        Swal.fire({
            title: 'ERROR!!!',
            text: 'Su password no coincide intentelo de nuevo',
            icon: 'error'});
    }

  }










}
