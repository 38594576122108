import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { _TOKEN } from '../../../environments/environment';
import { ConstanciasService} from '../../services/constancias.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'sb-contancias',
  templateUrl: './constancias.component.html',
  styleUrls: ['./constancias.component.css']
})
export class ContanciasComponent implements OnInit {

  constructor(private http: HttpClient,private _con: ConstanciasService,public router: Router) { }

  idx!:number;
  constancias:any;
  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
  }

  this.idx=sessionStorage.Id;
  this.getConstancias();
  }


  getConstancias(){
    this._con.getConstancias(this.idx).subscribe(
      cons => {
        this.constancias = cons;
        console.log(this.constancias);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  constancia(id:number,anio:number){
    window.open("https://api.comeppsi.com.mx/api/ConstanciaUser/"+id+"/"+anio);
  }

}
