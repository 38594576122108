import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment,_TOKEN } from '../../environments/environment';
import { DatosDir} from '../interfaces/datosdir';
import { Estudio} from '../interfaces/estudio';
import { Observable } from 'rxjs/Observable';
import { Usuarios } from '../interfaces/usuarios';

@Injectable({
  providedIn: 'root'
})
export class DirectorioService {

  constructor(private http: HttpClient) { }

  getDirectorio() {
    return this.http.get(`${environment.rutaAPI + '/GetDirectorio'}`);
  }

  getDatosPersonales(id:number) {
    return this.http.get(`${environment.rutaAPI + '/GetDatosPer/'+id}`);
  }

  getDAtosComp(id:number) {
    return this.http.get(`${environment.rutaAPI + '/GetDatosComp/'+id}`);
  }

  getEstudiosEsp(id:number) {
    return this.http.get(`${environment.rutaAPI + '/GetEstudiosEsp/'+id}`);
  }

  getDir(id:number) {
    return this.http.get(`${environment.rutaAPI + '/GetDir/'+id}`);
  }

  create(dato: DatosDir): Observable<DatosDir> {
    return this.http.post<DatosDir>(`${environment.rutaAPI + '/DatosComp' }`, dato);
  }

  createEstudio(Estudio: Estudio): Observable<Estudio> {
    return this.http.post<Estudio>(`${environment.rutaAPI + '/EstudioEsp' }`, Estudio);
  }

  delete(id: number): Observable<Estudio> {
    return this.http.post<Estudio>(`${environment.rutaAPI + '/DelEstudioEsp'}/${id}`,"");
    // return this.http.delete<Usuarios>("/api/Usuarios/"+id)
  }

  
  updateDatosPer(usr: Usuarios): Observable<Usuarios> {
    return this.http.post<Usuarios>(`${environment.rutaAPI + '/UpdateDatosPer'}/${usr.id}`, usr);
  }

  updateDatosCom(datos: DatosDir): Observable<DatosDir> {
    return this.http.post<DatosDir>(`${environment.rutaAPI + '/UpdateDatosCom'}/${datos.dir_usuario}`, datos);
  }

}
