<h3>Datos Para el Directorio</h3>
<br><br>
      <legend>Datos Personales</legend>
        <div class="tableFixHead">
            <table  class="table table-striped" style="font-size:12px;">
              <thead>
                <tr>
                    <th scope="col" ><span>Integrante</span></th>
                    <th scope="col" ><span>Colonia o Fraccionamiento</span></th>
                    <th scope="col" ><span>Teléfono</span></th>
                    <th scope="col" ><span>Celular</span></th>
                    <th scope="col" ><span>CP</span></th>
                    <th scope="col" ><span>Editar</span></th>
                </tr>
              </thead>
              <tbody>
                <tr  *ngFor="let dir of this.directorio;index as i">
                    <td>{{dir.nombrecompleto}}</td>
                    <td>{{dir.dire_completa}}</td>
                    <td>{{dir.telefono}}</td>
                    <td>{{dir.celular}}</td>
                    <td>{{dir.cp}}</td>
                    <td><button type="button" class="btn btn-primary btn-circle btn-sm"  data-toggle="modal" data-target="#UpdateDatos" (click)="getDatosPersonales(dir.id)"><i class="fa-solid fa-user-pen"></i></button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr>
            <legend>Datos Complementarios Para el Directorio</legend>
            <button type="button" class="btn btn-info btn-sm ml-2" data-toggle="modal" data-target=".bd-example-modal-lg" [disabled]="this.datoscount>0">Agregar Datos Complementarios</button>
            <div class="tableFixHead">
                <table  class="table table-striped" style="font-size:12px;">
                  <thead>
                    <tr>
                        <th scope="col" ><span>Título</span></th>
                        <th scope="col" ><span>Cédula</span></th>
                        <th scope="col" ><span>Rango de Honorarios</span></th>
                        <th scope="col" ><span>Descripción de Perfil</span></th>
                        <th scope="col" ><span>Editar</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  *ngFor="let dc of this.datoscomp;index as i">
                        <td>{{dc.dir_titulo}}</td>
                        <td>{{dc.dir_cedula}}</td>
                        <td>{{dc.dir_honorarios}}</td>
                        <td>{{dc.dir_desc_perfil}}</td>
                        <td><button type="button" class="btn btn-primary btn-circle btn-sm" data-toggle="modal" data-target="#DatoComp" > <i class="fa-solid fa-user-pen"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
<hr>
              <legend>Estudios o Especialidades</legend>
              <button type="button" class="btn btn-info btn-sm ml-2" data-toggle="modal" data-target="#exampleModalCenter">Agregar Estudios o Especilidades</button>
              <div class="tableFixHead">
                  <table  class="table table-striped" style="font-size:12px;">
                    <thead>
                      <tr>
                          <th scope="col" ><span>#</span></th>
                          <th scope="col" ><span>Descripción</span></th>
                          <th scope="col" ><span>Quitar</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr  *ngFor="let esp of this.especialidades;index as i">
                          <td><b>{{i+1}}</b></td>
                          <td>{{esp.esp_desc}}</td>
                          <td><button type="button" class="btn btn-danger btn-circle btn-sm" (click)="delEstudio(esp.esp_id)"><i class="fa fa-trash" aria-hidden="true"></i>
                          </button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Estudio o Especialidad</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <textarea name="textarea" rows="5" cols="50" [(ngModel)]="estudioesp.esp_desc"  class="form-control" placeholder="Agregue su Estudio o Especialidad"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" id="cerrarModal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="GuardaEstudio();">Guardar</button>
        </div>
      </div>
    </div>
  </div>


    <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="directorio" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Datos Complementarios para Directorio</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="titulo">Título</label>
                <input type="text" class="form-control input-sm" id="titulo" [(ngModel)]="datodir.dir_titulo" placeholder="Ej. Lic en Psicología Norma Contreras">
              </div>
              <div class="form-group col-md-6">
                <label for="cedula">Cédula</label>
                <input type="text" class="form-control input-sm" id="cedula" [(ngModel)]="datodir.dir_cedula" placeholder="Cédula Profesional">
              </div>
            </div>
            <div class="form-group">
              <label for="hono">Rango de Honorarios</label>
              <input type="text" class="form-control input-sm" id="hono"  [(ngModel)]="datodir.dir_honorarios" placeholder="Ej. $300 a $400 pesos">
            </div>
            <div class="form-group">
              <label for="desc">Breve Descripción del Perfil</label>
              <input type="text" class="form-control input-sm" id="desc"  [(ngModel)]="datodir.dir_desc_perfil" placeholder="Breve Descripción de su perfil, Maximo 500 caracteres" maxlength="500">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeModal" #closebutton>Cerrar</button>
            <button type="button" class="btn btn-primary" (click)="GuardaComp();">Guardar</button>
          </div>
        </div>
      </div>
    </div>

    <!--Modal update datos personales-->
    <div class="modal fade bd-example-modal-lg" id="UpdateDatos" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="false">
     <br><br>
      <div class="modal-dialog modal-lg modal-dialog-centered" >
        <div class="modal-content" *ngFor="let dp of this.datosper;index as i">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Datos Personales</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" >
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="paterno">Paterno</label>
                <input type="text" class="form-control input-sm" [(ngModel)]="dp.paterno" id="paterno"  >
              </div>
              <div class="form-group col-md-3">
                <label for="materno">Materno</label>
                <input type="text" class="form-control input-sm" [(ngModel)]="dp.materno" id="materno" >
              </div>
              <div class="form-group col-md-3">
                <label for="nombre">Nombre</label>
                <input type="text" class="form-control input-sm" [(ngModel)]="dp.nombre" id="nombre" >
              </div>
              <div class="form-group col-md-3">
                <label for="fecha">Fecha de Nacimiento</label>
                <input type="date" class="form-control input-sm" [(ngModel)]="dp.f_nacimiento" id="fecha" >
              </div>
              <div class="form-group col-md-3">
                <label for="email">E-Mail</label>
                <input type="email" class="form-control input-sm" [(ngModel)]="dp.email" id="email" >
              </div>
              <div class="form-group col-md-3">
                <label for="telefono">Teléfono</label>
                <input type="number" class="form-control input-sm" [(ngModel)]="dp.telefono" id="telefono" >
              </div>
              <div class="form-group col-md-3">
                <label for="cel">Celular</label>
                <input type="number" class="form-control input-sm" [(ngModel)]="dp.celular" id="cel" >
              </div>
              <div class="form-group col-md-3">
                <label for="cp">Codigo Postal</label>
                <input type="number" class="form-control input-sm" [(ngModel)]="dp.cp"  id="cp" >
              </div>
              <div class="form-group col-md-12">
                <label for="genero">Género </label>
                <select class="form-control sm" name="genero"  id="genero"  [(ngModel)]="dp.genero">
                  <option [ngValue]=1>MASCULINO</option>
                  <option [ngValue]=2>FEMENINO</option>
                  <option [ngValue]=3>NO DESEO MANIFESTAR</option>
              </select>
              </div>
              <div class="form-group col-md-9">
                <label for="direccion">Dirección</label>
                <input type="text" class="form-control input-sm" [(ngModel)]="dp.direccion" id="direccion" >
              </div>
              <div class="form-group col-md-3">
                <label for="noext">No Ext</label>
                <input type="number" class="form-control input-sm" [(ngModel)]="dp.no_ext"  id="noext" >
              </div>
              <div class="form-group col-md-12">
                <label for="calle1">Calle 1</label>
                <input type="text" class="form-control input-sm" [(ngModel)]="dp.calle1"  id="calle1" >
              </div>
              <div class="form-group col-md-12">
                <label for="calle2">Calle 2</label>
                <input type="text" class="form-control input-sm" [(ngModel)]="dp.calle2"  id="calle2" >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeUpdateDP" >Cerrar</button>
            <button type="button" class="btn btn-primary" (click)="UpdateDatosPer(dp);">Guardar</button>
          </div>
        </div>
      </div>
    </div>

<!--Modal update datos complementarios-->
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="updatocomp" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Datos Complementarios para Directorio</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="titulo">Título</label>
                <input type="text" class="form-control input-sm" id="titulo" [(ngModel)]="datodir.dir_titulo" placeholder="Ej. Lic en Psicología Norma Contreras">
              </div>
              <div class="form-group col-md-6">
                <label for="cedula">Cédula</label>
                <input type="text" class="form-control input-sm" id="cedula" [(ngModel)]="datodir.dir_cedula" placeholder="Cédula Profesional">
              </div>
            </div>
            <div class="form-group">
              <label for="hono">Rango de Honorarios</label>
              <input type="text" class="form-control input-sm" id="hono"  [(ngModel)]="datodir.dir_honorarios" placeholder="Ej. $300 a $400 pesos">
            </div>
          
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeModal" #closebutton>Cerrar</button>
            <button type="button" class="btn btn-primary" (click)="GuardaComp();">Guardar</button>
          </div>
        </div>
      </div>
    </div>



<!-- Modal -->
<div class="modal fade bd-example-modal-lg" id="DatoComp" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content" *ngFor="let dper of this.datoscomp;index as i">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Datos Complementarios para Directorio</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="titulo">Título</label>
            <input type="text" class="form-control input-sm" id="titulo" [(ngModel)]="dper.dir_titulo" placeholder="Ej. Lic en Psicología Norma Contreras">
          </div>
          <div class="form-group col-md-6">
            <label for="cedula">Cédula</label>
            <input type="text" class="form-control input-sm" id="cedula" [(ngModel)]="dper.dir_cedula" placeholder="Cédula Profesional">
          </div>
        </div>
        <div class="form-group">
          <label for="hono">Rango de Honorarios</label>
          <input type="text" class="form-control input-sm" id="hono"  [(ngModel)]="dper.dir_honorarios" placeholder="Ej. $300 a $400 pesos">
        </div>
        <div class="form-group">
          <label for="descrip">Descripción de Perfil</label>
          <textarea type="text" class="form-control input-sm" rows="5" id="descrip"  [(ngModel)]="dper.dir_desc_perfil" placeholder="Breve Descripción de su perfil"> </textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeDatosCom">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="UpdateDatosComp(dper);">Guardar</button>
      </div>
    </div>
  </div>
</div>

