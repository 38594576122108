import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Usuarios } from '../interfaces/usuarios';
import { HttpClient } from '@angular/common/http';
import { environment, _TOKEN } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AfiliadosService {

  constructor(private http: HttpClient, public router: Router) { }

  getAfiliados(anio:number) {
		
    return this.http.get(`${environment.rutaAPI + '/GetIntegrantes/'+anio}`);
  }

  getAnios() {
    return this.http.get(`${environment.rutaAPI + '/GetAnios'}`);
  }

  getDatosPersonales(id:number) {
    return this.http.get(`${environment.rutaAPI + '/GetDatosPer/'+id}`);
  }

  updateDatosPer(usr: Usuarios): Observable<Usuarios> {
    return this.http.post<Usuarios>(`${environment.rutaAPI + '/UpdateDatosAfiliados'}/${usr.id}`, usr);
  }

  getEnvioCorreo(id:number) {
    return this.http.get(`${environment.rutaAPI + '/EnviaCumple/'+id}`);
  }



}
