import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { _TOKEN } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DirectorioService} from '../../services/directorio.service';
import { DatosDir} from '../../interfaces/datosdir';
import { Estudio} from '../../interfaces/estudio';
import { Usuarios} from '../../interfaces/usuarios';
import Swal from 'sweetalert2';
import { ViewChild, ElementRef} from '@angular/core';
import * as $ from 'jquery';
import 'jqueryui';

@Component({
  selector: 'sb-datosdir',
  templateUrl: './datosdir.component.html',
  styleUrls: ['./datosdir.component.css']
})
export class DatosdirComponent implements OnInit {

  directorio:any;
  datosper:any;
  datoscomp:any;
  especialidades:any;
  idx!:number;
  datoscount!:number;
  public datodir: DatosDir= new DatosDir();
  public estudioesp: Estudio= new Estudio();



  constructor(private http: HttpClient,private _dir: DirectorioService,public router: Router) { }

  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
     }
     this.idx=sessionStorage.Id;
     this.getDirectorio();
     this.getDatosComp();
     this.getEstudiosEsp();
     $('#DatoComp').draggable(); 
     $('#updatocomp').draggable(); 
     $('#UpdateDatos').draggable(); 
     $('#directorio').draggable(); 
     $('#exampleModalCenter').draggable(); 
     
  }

  getDirectorio(){
    this._dir.getDir(this.idx).subscribe(
      dir => {
        this.directorio = dir;
        //console.log(this.directorio);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }

  getDatosPersonales(id:number){
    this._dir.getDatosPersonales(id).subscribe(
      dp => {
        this.datosper = dp;
       
        for(var i=0;i<this.datosper.length;i++){
          this.datosper[0].f_nacimiento=this.datosper[0].f_nacimiento.substring(0,10);
        }
        //console.log(this.datosper);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }

  getDatosComp(){
    this._dir.getDAtosComp(this.idx).subscribe(
      dc => {
        this.datoscomp = dc;
        console.log(this.datoscomp);
        console.log(this.datoscomp.length);
        this.datoscount=this.datoscomp.length;
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }

  getEstudiosEsp(){
    this._dir.getEstudiosEsp(this.idx).subscribe(
      est => {
        this.especialidades = est;
        //console.log(this.especialidades);
      },
      error => {
 
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }


  GuardaComp(){
    this.datodir.dir_usuario=this.idx;
    this._dir.create(this.datodir).subscribe(datos => {
      
      if(datos){
        Swal.fire('Datos complementarios', `Datos complementarios creados con éxito!`, 'success');
         this.router.navigate(['/datosdir']);
         var closebutton = document.querySelector('#closeModal') as HTMLVideoElement;
         closebutton.click();
         this.datodir.dir_titulo='';
         this.datodir.dir_cedula='';
         this.datodir.dir_honorarios='';
      }
      this.getDatosComp();

    });
  }

  GuardaEstudio(){
    this.estudioesp.esp_usuario=this.idx;
    this._dir.createEstudio(this.estudioesp).subscribe(datos => {
      
      if(datos){
        Swal.fire('Estudio o Especialidad', `Estudio o Especialidad creados con éxito!`, 'success');
         this.router.navigate(['/datosdir']);
         var closebutton = document.querySelector('#cerrarModal') as HTMLVideoElement;
         closebutton.click();
         this.estudioesp.esp_desc='';

      }
      this.getEstudiosEsp();
  
    });
  }

  delEstudio(id:number){

    this._dir.delete(id).subscribe(datos => {
      
      if(datos){
        Swal.fire('Estudio o Especialidad', `Estudio o Especialidad eliminados con éxito!`, 'success');
         this.router.navigate(['/datosdir']);
      }
      this.getEstudiosEsp();
  
    });
  }

  UpdateDatosPer(act: any): void {
    this._dir.updateDatosPer(act).subscribe(dp => {
      this.router.navigate(['/datosdir']);
        Swal.fire('Datos Personales ', `Datos Personales Actualizados con éxito!`, 'success');
        this.getDirectorio();
        var closebutton = document.querySelector('#closeUpdateDP') as HTMLVideoElement;
        closebutton.click();
    
    });
  }

  UpdateDatosComp(datos:any){
    this._dir.updateDatosCom(datos).subscribe(dp => {
      this.router.navigate(['/datosdir']);
        Swal.fire('Datos Complementarios ', `Datos Complementarios Actualizados con éxito!`, 'success');
        this.getDatosComp();
        var closebutton = document.querySelector('#closeDatosCom') as HTMLVideoElement;
        closebutton.click();
    
    });
  }
  
  
}
