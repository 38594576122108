import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment, } from '../../environments/environment';

import { Usuarios } from '../interfaces/usuarios';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegistrarService {

  private urlRegisro = `${environment.rutaAPI}/Registro`;

  constructor( private http: HttpClient ) { }

  create(usuario: any): Observable<any> {
    return this.http.post<any>(`${this.urlRegisro}`, usuario);
  }




}
