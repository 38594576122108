import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '../../services/guards/login.guard';
import { DefaultComponent } from './default.component';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { SettingsComponent } from '../settings/settings.component';
import { ContanciasComponent } from '../constancias/constancias.component';
import { AfiliadosComponent } from '../afiliados/afiliados.component';
import { DatosdirComponent } from '../datosdir/datosdir.component';
import { DirectorioComponent } from '../directorio/directorio.component';
import { DocsubidosComponent } from '../docsubidos/docsubidos.component';
import { DocumentosComponent } from '../documentos/documentos.component';
import { GraficaavanceComponent } from '../graficaavance/graficaavance.component';
import { PagoanualComponent } from '../pagoanual/pagoanual.component';
import { RecibosComponent } from '../recibos/recibos.component';
import { UpdpasswordComponent } from '../updpassword/updpassword.component';
import { VerificapagoComponent } from '../verificapago/verificapago.component';


const pagesRoutes: Routes = [
	{
		path: '',
		component: DefaultComponent,
		//canActivate: [ LoginGuard ],
		children: [
			
			{ path: 'afiliados', component: AfiliadosComponent },
			{ path: 'constancias', component: ContanciasComponent },
			{ path: 'dashboard', component: DashboardComponent },
			{ path: 'datosdir', component: DatosdirComponent },
			{ path: 'directorio', component: DirectorioComponent },
			{ path: 'docsubudos', component: DocsubidosComponent },
			{ path: 'documentos', component: DocumentosComponent },
			{ path: 'grafica', component: GraficaavanceComponent },
			{ path: 'pagoanual', component: PagoanualComponent },
			{ path: 'recibos', component: RecibosComponent },
			{ path: 'settings', component: SettingsComponent },
			{ path: 'updpass', component: UpdpasswordComponent },
			{ path: 'verificapago', component: VerificapagoComponent },
			

		]
	}
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
