<block-ui>
    <section class="gradient-custom">
        <div class="container py-5 h-100">
             <div class="row justify-content-center align-items-center h-100">
               <div class="col-12 col-lg-9 col-xl-7">
                 <div class="card shadow-2-strong card-registration" style="border-radius: 15px;">
                   <div class="card-body p-4 p-md-5">
                     <h3 class="mb-4 pb-2 pb-md-0 mb-md-5">Formulario de Registro</h3>
                  
         
                       <div class="row">
                         <div class="col-md-4 mb-4">
         
                           <div class="form-outline">
                             <label class="form-label" for="paterno"><b>Paterno</b></label>
                             <input type="text" id="paterno" class="form-control form-control-sm" placeholder="Apellido Paterno" [(ngModel)]="usuario.paterno" />
                           </div>
         
                         </div>
                         <div class="col-md-4 mb-4">
         
                           <div class="form-outline">
                             <label class="form-label" for="materno"><b>Materno</b></label>
                             <input type="text" id="materno" class="form-control form-control-sm" placeholder="Apellido Materno" [(ngModel)]="usuario.materno" />
                           </div>
         
                         </div>
       
                         <div class="col-md-4 mb-4">
         
                           <div class="form-outline">
                              <label class="form-label" for="nombre"><b>Nombre</b></label>
                             <input type="text" id="nombre" class="form-control form-control-sm" placeholder="Nombre" [(ngModel)]="usuario.nombre" />
                           </div>
         
                         </div>
                       </div>
         
                       <div class="row">
                         <div class="col-md-6 mb-4 d-flex align-items-center">
         
                           <div class="form-outline datepicker w-100">
                           <label for="birthdayDate" class="form-label"><b>Fecha de Nacimiento</b></label>
                             <input type="date" class="form-control form-control-sm" id="fnacimiento"  [(ngModel)]="usuario.f_nacimiento"/>
                             
                           </div>
         
                         </div>
       
                         <div class="col-md-6 mb-4 d-flex align-items-center">
         
                           <div class="form-outline datepicker w-100">
                               <label for="birthdayDate" class="form-label"><b>Género:</b></label>
                               <select class="form-control form-control-sm"  [(ngModel)]="usuario.genero">
                                   <option><b>--Seleccione una Opción--</b></option>
                                   <option [ngValue]="1">Masculino</option>
                                   <option [ngValue]="2">Femenino</option>
                                   <option [ngValue]="3">No deseo manifestar</option>
                                 </select>
                             
                           </div>
         
                         </div>
          
                       </div>
       
                       <div class="row">
                           <div class="col-md-4 mb-4">
           
                             <div class="form-outline">
                               <label class="form-label" for="telefono"><b>Teléfono</b></label>
                               <input type="number" id="telefono" class="form-control form-control-sm" placeholder="Teléfono" [(ngModel)]="usuario.telefono" />
                             </div>
           
                           </div>
                           <div class="col-md-4 mb-4">
           
                             <div class="form-outline">
                               <label class="form-label" for="celular"><b>Celular</b></label>
                               <input type="number" id="celular" class="form-control form-control-sm" placeholder="Celular" [(ngModel)]="usuario.celular"/>
                             </div>
           
                           </div>
         
                           <div class="col-md-4 mb-4">
           
                             <div class="form-outline">
                                <label class="form-label" for="cp"><b>Código Postal</b></label>
                               <input type="number" id="cp" class="form-control form-control-sm" placeholder="Código Postal" [(ngModel)]="usuario.cp"/>
                             </div>
           
                           </div>
                         </div>
       
                         <div class="row">
                           <div class="col-md-12 mb-12">
         
                               <div class="form-outline">
                                 <label class="form-label" for="direccion"><b>Dirección</b></label>
                                 <input type="text" id="direccion" class="form-control form-control-sm" placeholder="Ingrese Colonia o Fraccionamiento" [(ngModel)]="usuario.direccion" />
                               </div>
       
                               <div class="form-outline">
                                   <label class="form-label" for="calle2"><b>Número Exterior</b></label>
                                   <input type="number" id="noext" class="form-control form-control-sm" placeholder="Número Exterior" [(ngModel)]="usuario.no_ext"/>
                               </div>
       
                               <div class="form-outline">
                                   <label class="form-label" for="calle1"><b>Calle 1</b></label>
                                   <input type="text" id="calle1" class="form-control form-control-sm" placeholder="Ingrese calle 1 entre la que se encuentra su dirección" [(ngModel)]="usuario.calle1" />
                               </div>
       
                               <div class="form-outline">
                                   <label class="form-label" for="calle2"><b>Calle 2</b></label>
                                   <input type="text" id="calle2" class="form-control form-control-sm" placeholder="Ingrese calle 2 entre la que se encuentra su dirección" [(ngModel)]="usuario.calle2" />
                               </div>
             
                             </div>
                         </div><br>
       
       
                       <div class="row">
                         <div class="col-md-12 mb-4 pb-2">
         
                           <div class="form-outline">
                           <label class="form-label" for="email"><b>Email</b></label>
                           <input type="email" id="email" class="form-control form-control-sm" [(ngModel)]="usuario.email"/>
                           </div>
         
                         </div>
                         <div class="col-md-6 mb-4 pb-2">
         
                           <div class="form-outline">
                             <label class="form-label" for="pass1"><b>Password</b></label>
                             <input type="password" id="pass1" class="form-control form-control-sm"  [(ngModel)]="usuario.password"/>
                           </div>
         
                         </div>
       
                         <div class="col-md-6 mb-4 pb-2">
         
                           <div class="form-outline">
                             <label class="form-label" for="pass2"><b>Confirmar Password</b></label>
                             <input type="password" id="pass2" class="form-control form-control-sm"  [(ngModel)]="usuario.password2" />
                           </div>
         
                         </div>
                       </div>
                       
                       <div class="row">
                           <div class="col-md-6 mb-4 d-flex align-items-center">
                               <button type="button"class="btn btn-primary btn-lg btn-block" (click)="create(usuario)">Registrar</button>
                           </div>
                           <div class="col-md-6 mb-4 d-flex align-items-center">
                               <button type="button"class="btn btn-primary btn-lg btn-block" routerLink="/login">Login</button>
                           </div>
                       </div>
                   
         
                     <!--   <div class="mt-4 pt-2">
                         <input class="btn btn-primary btn-lg" type="submit" value="Submit" />
                       </div> -->
         
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
    </block-ui>
    