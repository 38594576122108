<h3>Recibos</h3>
    <form>
       
        <div class="tableFixHead">
            <table class="table table-striped" style="font-size:12px;">
                <thead>
                    <tr>
                        <th scope="col" ><span>Nombre</span></th>
                        <th scope="col" ><span>Fecha</span></th>
                        <th scope="col" ><span>Año</span></th>
                        <th scope="col" ><span>Recibo</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let rec of this.recibos;index as i">
                        <td>{{rec.nombre}}</td>
                        <td>{{rec.pago_fecha}}</td>
                        <td>{{rec.pago_anio}}</td>
                        <td><button type="button" class="btn btn-primary btn-sm" (click)="recibo(rec.id,rec.pago_anio)"><i class="fa-solid fa-file-image"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
       
  
    </form>

