import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { AfiliadosService } from '../../services/afiliados.service';
import { GraficaavanceService } from '../../services/graficaavance.service';
import { _TOKEN } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
/* import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts'; */
import { Chart } from 'chart.js'; 
import * as $ from 'jquery';
import 'jqueryui';
/* public barChartLabels: BaseChartDirective["labels"] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
 */

@Component({
  selector: 'sb-graficaavance',
  templateUrl: './graficaavance.component.html',
  styleUrls: ['./graficaavance.component.css']
})
export class GraficaavanceComponent implements OnInit {
/*   @ViewChild('myPieChart') myPieChart!: ElementRef<HTMLCanvasElement>; */
    chart!: Chart;
    corriente!:number;
    pendiente!:number;
    idx!:number;
    personas:any;
    years:any;
    arrayanios: string[] = []; 
    datag: number[] = []; 
    datalabes: string[] = ['Pendientes','Al corriente']; 
    datosp:any;
    Grafica1:any;
    aniogbl!:number;
    presidente!:any;
    anualidad!:any;
    rec!:any;
    valrec:any;

    

  constructor(private http: HttpClient,private _afiliado: AfiliadosService,private _grafica: GraficaavanceService,public router: Router) { }

  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
  }
    this.idx=sessionStorage.Id;
    this.getPresidente();
    this.getAnualidad();
    this.getAnios();
    this.getDatosGraficaNow();
    this.grafica();

    this.getRecaudacion();
    
    $('#exampleModalCenter').draggable(); 
    $('#exampleModalCenter2').draggable(); 
  }



  getPresidente(){
    this._grafica.getPresidente().subscribe(
      presi => {
        this.presidente = presi;
        console.log(this.presidente);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  getAnualidad(){
    this._grafica.getAnualidad().subscribe(
      anual=> {
        this.anualidad = anual;
        console.log(this.anualidad);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  getRecaudacion(){
    this._grafica.getRecaudacion().subscribe(
      rec=> {
        this.rec = rec;
        console.log(this.rec);
        this.valrec='$'+this.rec;
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  getRecaudacionAnio(anio:number){
    this._grafica.getRecaudacionAnio(anio).subscribe(
      rec=> {
        this.rec = rec;
        console.log(this.rec);
        this.valrec='$'+this.rec;
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  getAnios(){
    this._afiliado.getAnios().subscribe(
      anios => {
        this.years = anios;
        //console.log(this.years);
        this.arrayanios.push(this.years[0].anio1);
        this.arrayanios.push(this.years[0].anio2);
        this.arrayanios.push(this.years[0].anio3);
        console.log(this.arrayanios);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }

  getDatosGraficaNow(){
    this._grafica.getGraficaNow().subscribe(
      grap => {
        this.Grafica1 = grap;
        console.log(this.Grafica1);
        this.pendiente=this.Grafica1[0].pendientes;
        this.corriente=this.Grafica1[0].alcorriente;
        this.datag.push(this.Grafica1[0].pendientes);
        this.datag.push(this.Grafica1[0].alcorriente);
        console.log(this.pendiente);
        console.log(this.corriente);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }

  getDatosGraficaYear(anio:number){
    var canvas = document.getElementById('myChart') as HTMLCanvasElement;
    canvas.innerHTML="";
    this._grafica.getGraficaAnio(anio).subscribe(
      grap => {
        this.Grafica1 = grap;
        console.log(this.Grafica1);
        this.pendiente=this.Grafica1[0].pendientes;
        this.corriente=this.Grafica1[0].alcorriente;
        this.datag = [];
        this.datag.push(this.Grafica1[0].pendientes);
        this.datag.push(this.Grafica1[0].alcorriente);
        console.log(this.pendiente);
        console.log(this.corriente);
        this.grafica();
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });
  }


  grafica(){
    var canvas = document.getElementById('myChart') as HTMLCanvasElement;
    new Chart(canvas, {
    type: 'pie',    
    data: {
     /*  labels: this.datalabes, */
      labels: [ 'Pendientes',  'Al corriente'],
      datasets: [{
        data: this.datag,
        backgroundColor: ['#FF6384', '#36A2EB']
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
  
      },
    }
}); 
  }




UpdateAn(anualidad: any): void {
  this._grafica.updateAnualidad(anualidad).subscribe(an => {
    this.router.navigate(['/grafica']);
      Swal.fire('Anualidad ', `Anualidad Actualizada con éxito!`, 'success');
      this.getAnualidad();
      var closebutton = document.querySelector('#closeAn') as HTMLVideoElement;
      closebutton.click();
  });
}

UpdatePresi(presi: any): void {
  this._grafica.updatePresidente(presi).subscribe(an => {
    this.router.navigate(['/grafica']);
      Swal.fire('Presidente ', `Presidente Actualizado con éxito!`, 'success');
      this.getPresidente();
      var closebutton = document.querySelector('#closePres') as HTMLVideoElement;
      closebutton.click();
  });
}

}
