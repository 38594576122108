import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Documentos } from '../interfaces/documentos';
import { HttpClient } from '@angular/common/http';
import { environment, _TOKEN } from '../../environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class DocumentosService {
  private urlEndPoint = `${environment.rutaAPI}/RegDocs`;
  private urlDeleteArchivo = `${environment.rutaAPI}/DeleteFiles`;
  constructor(private http: HttpClient, public router: Router) { }

  create(Usuario: number,tipoDoc:number,nombreArchivo: string) {	
    //return this.http.post<Documentos>(`${environment.rutaAPI}` + '/RegDocs' + `/${Usuario}`, documento);
    return this.http.post(`${this.urlEndPoint + '?usuario='+Usuario+'&tipo_doc='+tipoDoc+'&nombreArchivo='+nombreArchivo}`, "");
	}

  Delete(RutaArchivo: string,id:number) {	
    //return this.http.post<Documentos>(`${environment.rutaAPI}` + '/RegDocs' + `/${Usuario}`, documento);
    return this.http.post(`${this.urlDeleteArchivo + '?ruta='+RutaArchivo+'&id='+id}`, "");
	}


  uploadAndProgressSingleImage(file: File,id:any) {
   return this.http
      .post(`${environment.rutaAPI}/UploadFiles/`+id, file, {
        reportProgress: true,
        observe: 'events',
      })
  }

  getDocumentos(ids:number) {
		
			return this.http.get(`${environment.rutaAPI + '/GetDocs/' + ids}`);
		}


}
