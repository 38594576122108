import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';


/* import { NgChartsModule,} from 'ng2-charts'; */



//rutas
import { APP_ROUTING } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { DefaultModule } from './modules/default/default.module';
import { ServicesModule } from './services/services.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeEsMx, 'es-Mx');
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { SettingsComponent } from './modules/settings/settings.component';
import { AfiliadosComponent } from './modules/afiliados/afiliados.component';
import { ContanciasComponent } from './modules/constancias/constancias.component';
import { RecibosComponent } from './modules/recibos/recibos.component';
import { DatosdirComponent } from './modules/datosdir/datosdir.component';
import { DirectorioComponent } from './modules/directorio/directorio.component';
import { DocumentosComponent } from './modules/documentos/documentos.component';
import { DocsubidosComponent } from './modules/docsubidos/docsubidos.component';
import { VerificapagoComponent } from './modules/verificapago/verificapago.component';
import { UpdpasswordComponent } from './modules/updpassword/updpassword.component';
import { PagoanualComponent } from './modules/pagoanual/pagoanual.component';
import { GraficaavanceComponent } from './modules/graficaavance/graficaavance.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { SpinnerModule } from './shared/components/spinner/spinner.module';
import { fromIterable } from 'rxjs/internal-compatibility';
import { SpinnerInterceptor } from './shared/interceptors/spinner.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    AfiliadosComponent,
    ContanciasComponent,
    RecibosComponent,
    DatosdirComponent,
    DirectorioComponent,
    DocumentosComponent,
    DocsubidosComponent,
    VerificapagoComponent,
    UpdpasswordComponent,
    PagoanualComponent,
    GraficaavanceComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    SharedModule,
    DefaultModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ServicesModule,
    BlockUIModule.forRoot(),
    NgxPaginationModule,
    AngularEditorModule,
    AngularFileUploaderModule,
    MatProgressBarModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatTableModule,
    HttpClientModule,
    SpinnerModule
    /*     NgChartsModule, */

  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-MX' }, { provide: LocationStrategy, useClass: HashLocationStrategy },{ provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor,multi:true }],
  bootstrap: [AppComponent],
  entryComponents: [

  ],
  

})
export class AppModule { }
