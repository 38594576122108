<!--
<mat-nav-list>

  <ng-container *ngFor="let rol of roles">

      <ng-container *ngIf="rol.rolu_modulo_pred === null">
        <a  mat-list-item routerLinkActive="list-item-active" routerLink="/{{rol.rolu_modulo_route}}">
          <mat-icon>pages</mat-icon> {{rol.rolu_modulo_descrip}}
        </a>
      </ng-container>
      <ng-container *ngIf="rol.rolu_modulo_pred !== null">
        <ng-container *ngIf="rol.rolu_modulo_route === null">

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>payment</mat-icon>{{rol.rolu_modulo_descrip}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLinkActive="list-item-active" routerLink="/posts"><mat-icon>payment</mat-icon> {{rol.rolu_modulo_descrip}}</a>
          </mat-expansion-panel>

        </ng-container>

      </ng-container>

  </ng-container>

</mat-nav-list>
-->


<mat-nav-list>
  

    <ng-container>
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard">
          <mat-icon>pages</mat-icon> Dashboard
      </a>
      <mat-divider></mat-divider>
    </ng-container>
<!--
   <ng-container *ngIf="tipoRol != '70' && tipoRol != '90'">
-->
    <ng-container>
      <h2 matSubheader><strong>Captura de Expediente</strong></h2>
      <div *ngIf="tipouser=='1'">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>perm_identity</mat-icon>Administrador
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLinkActive="list-item-active" routerLink="/afiliados"><mat-icon>assignment</mat-icon>Afiliados</a>
          <a mat-list-item routerLinkActive="list-item-active" routerLink="/verificapago"><mat-icon>calendar_today</mat-icon>Verificar Pagos</a>
          <a mat-list-item routerLinkActive="list-item-active" routerLink="/grafica"><mat-icon> pie_chart</mat-icon>Grafica de Avance</a>
      </mat-expansion-panel>
      </div>
 
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/updpass"><mat-icon>build</mat-icon>Password</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/documentos"><mat-icon>add_photo_alternate</mat-icon>Documentación</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/docsubudos"><mat-icon>folder_shared</mat-icon>Documentos Subidos</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/datosdir"><mat-icon>assignment_ind</mat-icon>Datos P/Directorio</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/directorio"><mat-icon> contact_mail</mat-icon>Directorio</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/constancias"><mat-icon>picture_as_pdf</mat-icon>Constancias</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/recibos"><mat-icon>picture_as_pdf</mat-icon>Recibos</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLinkActive="list-item-active" routerLink="/pagoanual"><mat-icon>credit_card</mat-icon>Anualidad</a>
      <mat-divider></mat-divider>
  </ng-container>


  <!--TABLERO DEL ADMINISTRADOR-->
  <!--
  <ng-container *ngIf="tipoRol === '70'">
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/banners"><mat-icon>photo_size_select_large</mat-icon> Banners</a>
    
    <mat-divider></mat-divider>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/listausuario"><mat-icon>supervised_user_circle</mat-icon> Lista de usuarios</a>

    <mat-divider></mat-divider>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/pagossiia"><mat-icon>attach_money</mat-icon> Ingresos desde portal</a>

    <mat-divider></mat-divider>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>note</mat-icon>Publicaciones
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/team"><mat-icon>note_add</mat-icon> Equipo</a>
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/faqs"><mat-icon>note_add</mat-icon> FAQs</a>
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/noticias"><mat-icon>note_add</mat-icon> Noticias</a>
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/services"><mat-icon>note_add</mat-icon> Servicios</a>
    </mat-expansion-panel>

    <mat-divider></mat-divider>
</ng-container>
-->
<!--TABLERO ESCOLARES-->
<!--
<ng-container *ngIf="tipoRol === '90'">

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/recepcionrecibos"><mat-icon>receipt</mat-icon> Recepción de recibos</a>

    <mat-divider></mat-divider>

</ng-container>
-->
</mat-nav-list>
