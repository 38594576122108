import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, _TOKEN } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecibosService {

  constructor(private http: HttpClient) { }

  getRecibos(ids:number) {
		
    return this.http.get(`${environment.rutaAPI + '/GetRecibos/' + ids}`);
  }
  
}
