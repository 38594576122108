<h3>Verificación de pago</h3>
    <form>
        <div class="form-group">
            <label for="anio">Año</label>
            <select class="form-control" (change)="getAfiliados($event.target.value)">
                <option selected="selected" value="0">-- Seleccione una año --</option>
                <option *ngFor="let cp of arrayanios" value="{{ cp }}">{{cp}}</option>
               </select>
        </div>
        <div class="tableFixHead">
            <table class="table table-striped" style="font-size:12px;">
                <thead>
                    <tr>
                        <th scope="col"><span>Integrante</span></th>
                        <th scope="col"><span>Anualidad</span></th>
                        <th scope="col"><span>Ver pago</span></th>
                        <th scope="col"><span>Enviar Recordatorio</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let af of this.personas;index as i">
                        <td>{{af.nombre_completo}}</td>
                        <td>{{af.estatus}}</td>
                        <td><button class="btn btn-success sm" [disabled]="af.estatus=='Pendiente'" (click)="verPago(af.id)">Ver pago</button></td>
                        <td><button class="btn btn-success sm" [disabled]="af.habilitar==1" (click)="enviaCorreo(af.id)">Enviar correo</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
      
    </form>
