import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login } from '../interfaces/login';
import { Observable } from 'rxjs/Observable';
import { environment,_TOKEN } from '../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor( private http: HttpClient, public router: Router ) { }

  private urlRegisro = `${environment.rutaAPI}/Registro`;
  private urlLogin = `${environment.rutaAPI}/Login`;



  create(usuario: any): Observable<any> {
    return this.http.post<any>(`${this.urlRegisro}`, usuario);
  }

  getLogin(login: Login): Observable<Login[]> {
  return this.http.post<Login>(this.urlLogin, login).pipe(
    map((response: any) => {
    sessionStorage.Login = response.email.toString();
    sessionStorage.Tipo = response.tipo_usuario;
    sessionStorage.Nombre = response.nombre;
    sessionStorage.Id = response.id;
    localStorage.setItem(_TOKEN, login.toString());
    //console.log(response);
          return response;
          
          })
  );
  }

  logout() {
    localStorage.removeItem(_TOKEN);
    sessionStorage.removeItem('Tipo');
    sessionStorage.removeItem('Login');
    sessionStorage.removeItem('Nombre');
    sessionStorage.removeItem('Id');
    // sessionStorage.removeItem(_TOKEN);
    this.router.navigate(['/auth/login']);
  }

  estaLogueado() {
    if (localStorage.getItem(_TOKEN) === null) {
    // if (sessionStorage.removeItem(_TOKEN) === null) {
      return false;
    } else {
      return localStorage.getItem(_TOKEN);
      //return (localStorage.getItem(_TOKEN).length > 5);
      // return (sessionStorage.getItem(_TOKEN).length>5);
    }
  }

  getCorreo(correo:string) {
    return this.http.get(`${environment.rutaAPI + '/RecuperaPassCorreo?correo='+correo}`);
  }

  
}
