<ng-container >
  <h3>Bienvenido/a: {{ user_name }}</h3>
<!--   <h2><strong>{{ user_name }}</strong></h2>
 -->  <span fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center" >
    <h4>{{today | date:'fullDate':'':'es-MX'}}</h4>
  </span> 
</ng-container>

<hr>


