import { Component, OnInit ,ViewChild} from '@angular/core';
import { Login } from '../../interfaces/login';
import { Subscription } from 'rxjs';
import { Router,  } from '@angular/router';
import swal from 'sweetalert2';
import { LoginService } from '../../services/login.service';
declare var $ : any;
/* import * as $ from 'jquery';
import 'jqueryui'; */
/* import { isNullOrUndefined, isUndefined } from 'util';
import { isEmpty } from 'rxjs/operators'; */


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
    @ViewChild('myModalClose') modalClose;
    private subscription: Subscription = new Subscription;
    public log: Login = new Login();
    correo!: string;
    spinLoader= false;
    constructor(private router: Router, private _log: LoginService) { }

    ngOnInit() {
        this.EventoEnter();
    }

    ngOnDestroy() {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    login(log: Login) {
        this.spinLoader= true;
      /*   var open = document.querySelector('#open') as HTMLVideoElement;
        var close = document.querySelector('#close') as HTMLVideoElement;
        open.click();  */
        //$('#open').click(); 
        this.subscription = this._log.getLogin(log)
            .subscribe((data: any) => {
                if (data != null) {
            
                    swal.fire({
                        icon: 'success',
                        title: 'Usuario Logeado',
                        text: 'Bienvenido ' + data.nombre,
                        timer: 2000
                    });
                    this.router.navigate(['/dashboard']);
                } else {
                
                  this.spinLoader= false;
                  swal.fire({
                        icon: 'error',
                        title: 'Usuario y/o contraseña incorrecta'
                    });
                  
                }
            },

                error => {
             
                    swal.fire({
                        title: 'ERROR!!!',
                        text: error.error.Message,
                        icon: 'error'
                    });
                   
                }

            );
            this.spinLoader= false;
       
    }


    repuperaPass(correo: string) {

        this._log.getCorreo(correo).subscribe(notifica => {
            if (notifica) {
                swal.fire({
                    icon: 'success',
                    title: 'Correo Enviado',
                    text: 'Se ha enviado un correo a ' + notifica
                });
                var closebutton = document.querySelector('#closeButton') as HTMLVideoElement;
                closebutton.click();
            }
        });
    }

    EventoEnter() {
        var input = document.getElementById("pass");
        input.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                document.getElementById("btnLogin").click();
            }
        });
    }


}
