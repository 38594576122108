import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { _TOKEN } from '../../../environments/environment';
import { RecibosService} from '../../services/recibos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'sb-recibos',
  templateUrl: './recibos.component.html',
  styleUrls: ['./recibos.component.css']
})
export class RecibosComponent implements OnInit {

  constructor(private http: HttpClient,private _rec: RecibosService,public router: Router) { }

  
  idx!:number;
  recibos:any;
  ngOnInit(): void {
    if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
  }

  this.idx=sessionStorage.Id;
  this.getConstancias();
  }


  getConstancias(){
    this._rec.getRecibos(this.idx).subscribe(
      rec => {
        this.recibos = rec;
        console.log(this.recibos);
      },
      error => {
        //console.log(error);
        Swal.fire({title: 'ERROR!!!',text: error.message,icon: 'error'});
      });

  }

  recibo(id:number,anio:number){
    window.open("https://api.comeppsi.com.mx/api/ReciboUser/"+id+"/"+anio);
  }

}
