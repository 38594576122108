import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { _TOKEN } from '../../../environments/environment';
import { DocumentosService} from '../../services/documentos.service';
import Swal from 'sweetalert2';
import {Documentos} from '../../interfaces/documentos';


@Component({
  selector: 'sb-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {
  percentDone!: number;
  uploadSuccess!: boolean;
  idx!:number;
  public Docs: Documentos = new Documentos();
  selectedFile!: File;
  public urlEndPoint: string = `${environment.rutaAPI}`;
  progress : any;
  progress2 : any;
  progress3 : any;
  progress4 : any;
  progress5 : any;
  progress6 : any;
  progress7 : any;
  progress8 : any;
  progress9 : any;
  progress10 : any;
  progress11 : any;

  btn1: boolean= true;
  btn2: boolean= true;
  btn3: boolean= true;
  btn4: boolean= true;
  btn5: boolean= true;
  btn6: boolean= true;
  btn7: boolean= true;
  btn8: boolean= true;
  btn9: boolean= true;
  btn10: boolean= true;
  btn11: boolean= true;
 
  mensaje : any;
  constructor(private http: HttpClient,private _doc: DocumentosService,public router: Router) {
    
   }

  ngOnInit(): void {
  if(localStorage.getItem(_TOKEN)===null){
      this.router.navigate(['/login']);
  }

  this.idx=sessionStorage.Id;
  }

  onFileSelected(event:any){
    this.selectedFile = <File>event.target.files[0];
    //this.ToggleButton = false;
  }

  Habilitar(param:number){
     switch(param){
        case 1:
        this.btn1 = false;
        break;
        case 2:
        this.btn2 = false;
        break;
        case 3:
        this.btn3 = false;
        break;
        case 4:
        this.btn4 = false;
        break;
        case 5:
        this.btn5 = false;
        break;
        case 6:
        this.btn6 = false;
        break;
        case 7:
        this.btn7 = false;
        break;
        case 8:
        this.btn8 = false;
        break;
        case 9:
        this.btn9 = false;
        break;
        case 10:
        this.btn10 = false;
        break;
        case 11:
        this.btn11 = false;
        break;
     }
  }

  onUpload(tipoDoc:number){
    const fd = new FormData();
    fd.append('image', this.selectedFile, this.selectedFile.name);
    //console.log(this.idx);
    this._doc.create(this.idx,tipoDoc,this.selectedFile.name).subscribe(archivo =>{
        //console.log(archivo);
        this.http.post(`${this.urlEndPoint+"/UploadFiles?ruta="+this.selectedFile.name+"&id="+archivo}`,fd,{
          reportProgress: true,
          observe: 'events'
        }).subscribe(event=>{
          if (event.type === HttpEventType.UploadProgress){
            //console.log("Progreso: " + Math.round (event.loaded / event.total! * 100)  + "%");
            switch(tipoDoc){
              case 1:
                this.progress = Math.round (event.loaded * 100 / event.total! );
                break;
              case 2:
                this.progress2 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 3:
                this.progress3 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 4:
                this.progress4 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 5:
                this.progress5 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 6:
                this.progress6 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 7:
                this.progress7 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 8:
                this.progress8 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 9:
                this.progress9 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 10:
                this.progress10 = Math.round (event.loaded * 100 / event.total! );
                break;
              case 11:
                this.progress11 = Math.round (event.loaded * 100 / event.total! );
                break;
            }
            //this.progress = Math.round (event.loaded * 100 / event.total! );
          }
          else if(event.type === HttpEventType.Response){
            console.log(event);
            if (event.status == 201){
              Swal.fire('Guardado', `Archivo  creado con éxito!`, 'success');
            }
          }

        },error=>{
          //console.log(error);
          Swal.fire({
            title: 'ERROR!!!',
            text: error.message,
            icon: 'error'});
        })

    },
    error => {
      //console.log(error);
      Swal.fire({
        title: 'ERROR!!!',
        text: error.message,
        icon: 'error'});
    });

  }
}